<template>
  <div class="pmd-footer">
    <v-row style="background: #2b2b2b; border-top: 1px solid rgb(204 203 203)">
      <v-col cols="12" xl="6" lg="6" md="12" sm="12">
        <v-card
            flat
            tile
            class="transparent"
        >
          <div class="pt-7 px-5 white--text">
              {{$t('$vuetify.footerDashboard.title[0]')}}
            <v-btn rounded class="primary" small>
              <span
                  style="display: inline-block;direction:ltr !important; text-align: left">
                <a class="white--text no-decoration"
                   href="whatsapp://send?phone=+989302672054\">+989302672054</a>
              </span>
            </v-btn>
            {{$t('$vuetify.footerDashboard.title[1]')}}
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="12" sm="12">
        <v-row justify="center" dense v-if="profile != undefined">
          <template v-for="(link,i) in appLinks" >
            <v-col cols="12" lg="5" class="ma-sm-1"  :key="i" v-if="profile.role == link.requiredRoles || link.requiredRoles == undefined">
              <v-btn block rounded class="white elevation-25"
                     :href="link.href"
                     target="_blank"
              >
                <v-icon class="mx-1" :class="link.color">{{ link.icon }}</v-icon>
                <span style="text-decoration: none" class="mt-1">{{ $t(link.text) }}</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
    <v-row style="background-color: #34316e;">
      <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="ma-0 py-0 pr-3">
        <v-card-text class=" text-center" style="direction: rtl;color:#838484;">
          <span class="mx-1">Copyright 2022 |Simiaroom | All Rights Reserved</span>
          <v-icon small dark class="" style="color:#838484;">far fa-copyright</v-icon>
          {{ new Date().getFullYear() }}
        </v-card-text>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="pt-0 pb-2 px-0 ma-0">
        <v-card-text class="pr-1 pb-1 pl-1 pt-2 ma-0 text-center">
          <v-btn
              v-for="(socialAccount,index) in socialAccounts"
              :key="index"
              class="mx-3 text-center justify-center elevation-25"
              socialAccount
              :href="socialAccount.link"
              target="_blank"
              fab small
          >
            <v-icon :color="socialAccount.color">{{ socialAccount.icon }}</v-icon>
          </v-btn>
        </v-card-text>
      </v-col>
    </v-row>

  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    appLinks: [
      {
        text: '$vuetify.footerDashboard.appLinks.android',
        href: 'https://play.google.com/store/apps/details?id=com.simiaroompatientapp',
        icon: 'fab fa-android',
        color: 'green--text',
        requiredRoles:"customer",

      },

      {
        text: '$vuetify.footerDashboard.appLinks.doctor',
        href: 'https://play.google.com/store/apps/details?id=com.simiaroomdoctorapp',
        icon: 'fab fa-android',
        color: 'green--text',
        requiredRoles:"counselor",
      },

      {
        text: '$vuetify.footerDashboard.appLinks.iosDoctor',
        href: 'https://apps.apple.com/us/app/platroomdoctor/id1586611928',
        icon: 'fab fa-apple',
        requiredRoles:"counselor",
      },
      {
        text: '$vuetify.footerDashboard.appLinks.iosPatient',
        href: 'https://apps.apple.com/us/app/platroom/id1516231951',
        icon: 'fab fa-apple',
        requiredRoles:"customer",
      },
    ],
    socialAccounts: [
      {
        icon: 'fab fa-facebook',
        link: 'https://www.facebook.com/simiatalk',
        color: "#1c4a97"
      },
      {
        icon: 'fab fa-twitter',
        link: 'https://twitter.com/SimiaRoom',
        color: "#1c9cea"
      },

      {
        icon: 'fab fa-linkedin',
        link: 'https://www.linkedin.com/company/simiaroom/',
        color: "#2464ad"

      },
      {
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/simiaroomcom/',
        color: "#b72e7a"

      },
      {
        icon: 'fab fa-youtube',
        link: 'https://www.youtube.com/channel/UCgM4KoG4MZg-ATBchGzQnAw/videos',
        color: "#f70000"
      },
    ],
  }),
  mounted() {
    console.log(this.profile)
  },
  computed :{
    ...mapGetters({
      profile:"getUserInfo"
    })
  }
}
</script>