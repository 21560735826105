<template>
  <div>
    <v-list id="navIconItems" class="mb-0 py-0" flat>
      <!--            <v-list-item>
                      <v-list-item-avatar>
                          <img
                                  style="object-fit: cover"
                                  :src="require('./../../assets/avatar-default.png')"/>
                      </v-list-item-avatar>

                      <v-list-item-title>
                          <v-btn text class="font-weight-bold" :to="{name:'profile'}">{{ $store.getters.getUserInfo.firstname
                              + ' ' + $store.getters.getUserInfo.lastname }}
                          </v-btn>
                          <p class="secondary&#45;&#45;text px-4" style="font-size: .6rem">مشاهده پروفایل
                              <v-icon color="secondary" small>mdi-chevron-left</v-icon>
                          </p>
                      </v-list-item-title>
                  </v-list-item>-->
      <v-list-item two-line justify="center" class="indigo text-center pt-3" style="display: contents">
        <div class="indigo pt-4 pb-4 d-none d-lg-block">
          <v-img style="width: 100px; margin:0 auto;" src="@/assets/simialogovertical.png" alt="Simiaroom Logo"/>
        </div>
        <div class="indigo pt-4 pb-3 d-lg-none">
          <v-img
              width="56px"
              height="56px"
              class="navigation-avatar"
              :src="getUserInfo.avatar ? getUserInfo.avatar : require('@/assets/avatar-default.png')"
              alt="avatar"
          ></v-img>
          <span style="display: block" class="white--text pt-3"
                v-if="getUserInfo">{{ getUserInfo.full_name }}</span>
        </div>
      </v-list-item>
      <!--<template-->
      <!--v-if="getUserInfo != null && isLoggedin && $store.getters.userHasRole('customer')">-->
      <!--<v-list-item v-for="(item,currency) in getUserInfo.monetary_wallet"-->
      <!--:key="currency" dense-->
      <!--&gt;-->
      <!--&lt;!&ndash;                    <v-list-item-action>-->
      <!--<v-icon>fas fa-wallet</v-icon>-->
      <!--</v-list-item-action>&ndash;&gt;-->
      <!--<v-list-item-title style="text-align: center"-->
      <!--:class="{'orange&#45;&#45;text':item.balance>0,'red&#45;&#45;text':item.balance<=0}">-->
      <!--کیف پول شما-->
      <!--<div style="direction:ltr;display: inline-block;">-->
      <!--{{item.balance | resolvePrice(currency)}}-->
      <!--</div>-->
      <!--{{currency | resolveCurrency}}-->
      <!--</v-list-item-title>-->
      <!--&lt;!&ndash;<v-list-item-content>&ndash;&gt;-->
      <!--&lt;!&ndash;&ndash;&gt;-->
      <!--&lt;!&ndash;</v-list-item-content>&ndash;&gt;-->

      <!--</v-list-item>-->


      <!--</template>-->
      <v-divider></v-divider>

      <template v-for="item in items">
        <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
              cols="6"
              class="text-center"
          >
            <a
                href="#!"
                class="body-2 black--text"
            >EDIT</a>
          </v-col>
        </v-row>
        <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            append-icon="expand_more"
        >
          <template v-slot:activator>
            <v-list-item-action class="ml-4" v-if="item.icon">
              <v-icon class="purple-light--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="purple-light--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child, i) in item.children">
            <v-list-item
                v-if="!child.isBtn"
                :key="i"
                link
                class="mx-3"
                :to="{name:child.routeName}"
                style="border-bottom: 1px solid #312e5b"
            >
              <v-list-item-action class="ml-4" v-if="child.icon">
                <v-icon class="purple-light--text">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="purple-light--text">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-else
                :key="i"
                link
                class="mx-3"
                @click="child.method"
                style="border-bottom: 1px solid #312e5b"
            >
              <v-list-item-action class="ml-4" v-if="child.icon">
                <v-icon class="purple-light--text">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="purple-light--text">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!--                    <v-list-item
                                      v-for="(child, i) in item.children"
                                      :key="i"
                                      link
                                      class="mx-3"
                                      :to="{name:child.routeName}"
                                      style="border-bottom: 1px solid #312e5b"
                              >
                                  <v-list-item-action class="ml-4" v-if="child.icon">
                                      <v-icon class="purple-light&#45;&#45;text">{{ child.icon }}</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title class="purple-light&#45;&#45;text">
                                          {{ child.text }}
                                      </v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>-->
        </v-list-group>
        <template v-else>
          <v-list-item
              v-if="!item.isBtn"
              :key="item.text"
              link
              :to="{name:item.routeName}"
              style="border-bottom: 1px solid #312e5b"
          >
            <v-list-item-action class="ml-4">
              <v-icon class="purple-light--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="purple-light--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              v-else
              :key="item.text"
              @click="item.method"
              style="border-bottom: 1px solid #312e5b"
          >
            <v-list-item-action class="ml-4">
              <v-icon class="purple-light--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="purple-light--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
      <!--            <v-list-item :to="{name:'wallet'}" style="border-bottom: 1px solid #312e5b">
                    <v-list-item-action class="ml-4">
                      <v-icon class="purple-light&#45;&#45;text">mdi-format-list-bulleted</v-icon>
                    </v-list-item-action>
                    <v-list-item-title  class="purple-light&#45;&#45;text"
                    >
                      جلسات باقی مانده
                    </v-list-item-title>

                  </v-list-item>-->
    </v-list>

  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import {mapGetters} from 'vuex';
import {resolveCurrency} from "../../utilities";

export default {
  data() {
    return {
      items: [
        {
          method: null,
          routeName: 'dashboard',
          icon: 'mdi-view-dashboard',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.dashboard')
        },
        {
          method: null,
          routeName: 'sessions',
          icon: 'mdi-calendar',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.sessions'),
          // children: [
          //   {
          //     method: null,
          //     routeName: 'sessions',
          //     icon: 'mdi-calendar',
          //     text: 'جلسات'
          //   },
          //   {
          //     method: null,
          //     routeName: 'awaiting_sessions',
          //     icon: 'mdi-calendar',
          //     text: 'جلسات در انتظار تایید'
          //   },
          //   {
          //     method: null,
          //     routeName: 'wallet',
          //     icon: 'mdi-format-list-bulleted',
          //     text: 'جلسات باقی مانده'
          //   }
          // ]
        },
        {
          method: null,
          routeName: 'products',
          icon: 'fas fa-heartbeat',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.buySession')
        },
        {
          method: null,
          routeName: 'clientFactors',
          icon: 'fas fa-receipt',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.factors')
        },
        {
          method: null,
          routeName: 'userMonetaryWallet',
          icon: 'mdi-wallet',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.wallet')
        },
        {
          method: null,
          routeName: 'clientInvitations',
          icon: 'mdi-face',
          text: this.$vuetify.lang.t('$vuetify.ClientNavItem.invitation')
        },

      ]
    }
  },
  methods: {

    openSessionRequestDialog() {
      EventBus.$emit('showAddSessionDialog');
    },
    openInvite() {
      EventBus.$emit('openInvite');
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
    },
  },
  computed: {
    ...mapGetters({
          getUserInfo: 'getUserInfo',
          isLoggedin: 'userIsLoggedIn'
        }
    ),
    showNavIcon() {
      // return true;
      return this.$vuetify.breakpoint.mdAndDown;
    },
    showTopMenu() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  filters: {

    resolvePrice(amount, currency) {
      return currency === 'dollar' ? amount.toFixed(2) : amount.toLocaleString();
    }
  },
}
</script>

<style>
#navIconItems .v-btn__content {
  justify-content: right;
}
</style>